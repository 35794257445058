import gql from 'graphql-tag';

export default gql`
query getForms {
  getForms {
    form_id
    description
    name
    form_data
  }
}
`;
